const getTextNodeAtPosition = (root, index) => {
  const treeWalker = document.createTreeWalker(
    root,
    NodeFilter.SHOW_TEXT,
    function next(elem) {
      if (index >= elem.textContent.length) {
        index -= elem.textContent.length
        return NodeFilter.FILTER_REJECT
      }
      return NodeFilter.FILTER_ACCEPT
    }
  )
  const c = treeWalker.nextNode()
  return {
    node: c ? c : root,
    position: c ? index : 0,
  }
}

const saveCaretPosition = context => {
  const selection = window.getSelection()
  const range = selection.getRangeAt(0)
  range.setStart(context, 0)
  const len = range.toString().length

  return () => {
    const pos = getTextNodeAtPosition(context, len)
    selection.removeAllRanges()
    const range = new Range()
    range.setStart(pos.node, pos.position)
    selection.addRange(range)
  }
}

export default saveCaretPosition
