// @flow
import React from 'react'
import Prism from 'prismjs'
import { js as beautify } from 'js-beautify'
import { Button } from '@conceptsauce/react-stack'

import saveCaretPosition from './saveCaretPosition'
import findPrevCodeNode from './findPrevCodeNode'
import ConsoleContext from 'src/components/console/ConsoleContext'

type Props = {
  disableCodeEdit: boolean,
  example: string,
  updateLog: (string, any) => void,
}

// In order this component to work, it must be placed after code block
class Editor extends React.PureComponent<Props> {
  executeBtn = React.createRef()

  highlight = e => {
    const restore = saveCaretPosition(e.target)
    Prism.highlightElement(e.target)
    restore()
  }

  prettify = e => {
    e.target.innerHTML = beautify(e.target.innerText, { indent_size: 2 })
    Prism.highlightElement(e.target)
  }

  componentDidMount() {
    const { disableCodeEdit, hideRelatedCode } = this.props
    if (disableCodeEdit || !this.executeBtn || !this.executeBtn.current) return
    let codeNode = findPrevCodeNode(this.executeBtn.current)
    codeNode = codeNode.getElementsByTagName('code')[0]
    if (!codeNode) return

    const parent = codeNode.parentNode.parentNode

    if (!hideRelatedCode) {
      codeNode.setAttribute('contenteditable', true)
      codeNode.addEventListener('input', this.highlight)
      codeNode.addEventListener('blur', this.prettify)
      codeNode.setAttribute('spellcheck', 'false')
      const hint = document.createElement('div')
      hint.style.fontSize = 'small'
      hint.style.fontStyle = 'italic'
      hint.appendChild(
        document.createTextNode(
          (!disableCodeEdit ? 'Click on the code to edit. ' : '') +
            'Use console.log to display results'
        )
      )
      parent.parentNode.insertBefore(hint, parent.nextSibling)
    } else parent.style.display = 'none'
  }

  componentWillUnmount() {
    if (
      this.props.disableCodeEdit ||
      !this.executeBtn ||
      !this.executeBtn.current
    )
      return
    let codeNode = findPrevCodeNode(this.executeBtn.current)
    if (!codeNode) return
    codeNode = codeNode.getElementsByTagName('code')[0]
    codeNode.addEventListener('input', this.highlight)
    codeNode.removeEventListener('blur', this.prettify)
  }

  runCode = async (e: SyntheticEvent<HTMLInputElement>) => {
    const { example, updateLog } = this.props
    const codeNode = findPrevCodeNode(this.executeBtn.current)
    if (!codeNode) return
    // eslint-disable-next-line
    const logs = await eval(`
      (async () => {
        const logs = []
        const _log = console.log
        console.log = (...args) => {
          _log.apply(null, args)
          logs.push(...args)
        }
        try {
          ${codeNode.innerText}
        } catch (e) {
          logs.push(e)
          console.error(e)
        }
        console.log = _log
        return logs
      })();`)
    updateLog(example, logs)
  }

  render() {
    return (
      <div ref={this.executeBtn}>
        <Button
          width="160px"
          bg="blue"
          m={3}
          type="button"
          // $FlowFixMe
          onClick={this.runCode}
          value="Execute"
        />
      </div>
    )
  }
}

// $FlowFixMe
export default React.forwardRef((props, ref) => (
  <ConsoleContext.Consumer>
    {({ updateLog }) => <Editor {...props} updateLog={updateLog} ref={ref} />}
  </ConsoleContext.Consumer>
))
