// @flow
import React from 'react'
import JsonTree from 'react-json-tree'
import styled from 'styled-components'

import ConsoleContext from './ConsoleContext'

type Props = {
  example: string,
}

const ReactConsole = ({ example }: Props) => (
  <ConsoleContext.Consumer>
    {({ logs }) => (
      <Wrapper>
        {logs[example] &&
          logs[example].map((log, i) => (
            <JsonTree
              key={i}
              keyPath={['console.log']}
              theme={theme}
              data={log}
            />
          ))}
      </Wrapper>
    )}
  </ConsoleContext.Consumer>
)

export default ReactConsole

const Wrapper = styled.div`
  min-height: 125px;
  padding: 5px;
  margin-bottom: 16px;
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  background-color: #fff;
  font-size: 14px;
`

const theme = {
  scheme: 'solarized',
  author: 'ethan schoonover (http://ethanschoonover.com/solarized)',
  base00: '#030201',
  base01: '#073642',
  base02: '#586e75',
  base03: '#657b83',
  base04: '#839496',
  base05: '#93a1a1',
  base06: '#eee8d5',
  base07: '#fdf6e3',
  base08: '#dc322f',
  base09: '#cb4b16',
  base0A: '#b58900',
  base0B: '#859900',
  base0C: '#2aa198',
  base0D: '#268bd2',
  base0E: '#6c71c4',
  base0F: '#d33682',
}
