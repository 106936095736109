// @flow
import * as React from 'react'
import { MDXProvider } from '@mdx-js/tag'

import Layout from 'src/components/layout'
import Link from 'src/components/CSLink'

type Props = {
  children: React.Node,
  pageContext: {
    frontmatter: {
      title?: string,
      sidebar: false | string,
      meta: Array<any>,
    },
  },
}

const Mdx = ({ children, pageContext: { frontmatter } }: Props) => (
  <MDXProvider components={{ a: Link }}>
    <Layout frontmatter={frontmatter}>{children}</Layout>
  </MDXProvider>
)

export default Mdx
