import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/angel/conceptsauce/syrup/packages/site/src/templates/mdx.js"
import Editor from 'src/components/editor'
import Console from 'src/components/console'

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>


<MDXTag name="h2" components={components}>{`The get function`}</MDXTag>
<MDXTag name="p" components={components}><code className="language-text">get</code>
{` is a built in function to `}<code className="language-text">TAFFYdb</code>
{` and returns an `}<code className="language-text">Array</code>
{` of objects, one per record, of the source `}<code className="language-text">qObj</code>
{` it is called on.`}</MDXTag>
<MDXTag name="p" components={components}>{`You may have noticed a lot of the examples in these docs end with `}<code className="language-text">.get</code>
{` as the last method used and then we console.log the result. This is because `}<code className="language-text">get</code>
{` is very useful for debugging queries and understanding the structures returned at differing stages of a query.`}</MDXTag>
<MDXTag name="p" components={components}>{`Run the code below then examin the results to get a feel for `}<code className="language-text">get</code>
{`.`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token comment">// log all questions data from a qObj</span>{"\n"}{"\n"}<span className="token keyword">let</span> results <span className="token operator">=</span> r<span className="token punctuation">.</span><span className="token function">questions</span><span className="token punctuation">(</span><span className="token punctuation">)</span><span className="token punctuation">.</span><span className="token keyword">get</span><span className="token punctuation">(</span><span className="token punctuation">)</span>{"\n"}console<span className="token punctuation">.</span><span className="token function">log</span><span className="token punctuation">(</span>results<span className="token punctuation">)</span></code></pre></div>

<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">var</span> r <span className="token operator">=</span> <span className="token keyword">await</span> syrup<span className="token punctuation">.</span><span className="token function">fetchRespondentData</span><span className="token punctuation">(</span><span className="token punctuation">{"{"}</span>{"\n"}{"  "}projectID<span className="token punctuation">:</span> <span className="token string">'8fcbb2dc-8093-40d7-6c41-88ecccdcb2aa'</span><span className="token punctuation">,</span>{"\n"}{"  "}pageID<span className="token punctuation">:</span> <span className="token string">'b8b70dca-a5b8-4a1a-4a8f-f60254fea95c'</span><span className="token punctuation">,</span>{"\n"}{"  "}respondentID<span className="token punctuation">:</span> <span className="token string">'HNTEST12'</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span><span className="token punctuation">)</span>{"\n"}{"\n"}<span className="token keyword">let</span> results <span className="token operator">=</span> r<span className="token punctuation">.</span><span className="token function">questions</span><span className="token punctuation">(</span><span className="token punctuation">)</span><span className="token punctuation">.</span><span className="token keyword">get</span><span className="token punctuation">(</span><span className="token punctuation">)</span>{"\n"}console<span className="token punctuation">.</span><span className="token function">log</span><span className="token punctuation">(</span>results<span className="token punctuation">)</span></code></pre></div>

<Editor hideRelatedCode example="real-example" />
<Console example="real-example" />
           </MDXTag>
  }
}

export const _frontmatter = {};

  